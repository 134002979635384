* {
  padding: 0;
  margin: 0;
}

/* background */
body { 
background: linear-gradient(-30deg, rgb(240, 131, 63), rgb(255, 255, 255), rgb(82, 135, 248));
background-size: 400% 400%;
animation: gradient 48s ease infinite;
  /* background-color: deepskyblue; */
}

@keyframes gradient {
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}



/* welcome component */

.title {
  font-size: 8rem;
}

.welcome {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  animation: removeDiv 7s 1 forwards 3s;
}

.welcome h1 {
  margin: 0;
  color: #f9f9f9;
  border-right: 3px solid #f9f9f9;
  animation: titleLoad 6s 1 forwards, caret .8s infinite;
  white-space: nowrap;
  overflow: hidden;
}

/* Make animation that 
waits for tilte to fade out 
then display a button in the top left corner
*/

.home-button {
  color: #212121;
  animation: lateFadeIn 6s ease-in 1 forwards;
  font-family: 'Sassy Frass', cursive;
  font-size: 6rem;
  z-index: 100;
}
@keyframes lateFadeIn {
  0%{
      opacity: 0%;
  }
  90%{
      opacity: 0%;
  }
  100%{
      opacity: 100%;
  }

}

.maintenanceLoad {
  animation: slowLoad 7s linear 1;
  margin-top: 20%;
}
@keyframes slowLoad {
  0%{
    opacity: 0%;
  }
  80%{
    opacity: 0%;
  }
  100%{
    opacity: 100%;
  }
}

.shadow {
  border-radius: 80%;
  background-color: gray;
  height: 50px;
  width: 250px;
  position: relative;
  top: 80px;
  z-index: -100;
  opacity: 10%;
}

.loading-cube1 {
  height: 50px;
  width: 50px;
  background-color:deepskyblue;
  animation: loadingCube1 4s linear infinite;
  position: relative;
  left: 30%;
  border-radius: 15%;
}
.loading-cube2 {
  height: 50px;
  width: 50px;
  background-color:gray;
  animation: loadingCube2 4s linear infinite;
  position: relative;
  left: 45%;
  border-radius: 15%;
}
.loading-cube3 {
  height: 50px;
  width: 50px;
  background-color:coral;
  animation: loadingCube3 4s linear infinite;
  position: relative;
  left: 60%;
  border-radius: 15%;
}
@keyframes loadingCube1 {
  0%{
    position: absolute;
    top: 100px;
    
  }
  50%{
    position: absolute;
    top: 50px;

  }
  100%{
    position: absolute;
    top: 100px;
    rotate: 900deg;
  }

}
@keyframes loadingCube2 {
  0%{
    position: absolute;
    top: 50px;
    
  }
  50%{
    position: absolute;
    top: 100px;

  }
  100%{
    position: absolute;
    top: 50px;
    rotate: 900deg;
  }

}
@keyframes loadingCube3 {
  0%{
    position: absolute;
    top: 100px;
    
  }
  50%{
    position: absolute;
    top: 50px;

  }
  100%{
    position: absolute;
    top: 100px;
    rotate: 900deg;
  }

}





@keyframes caret {
  0%, 100% {
      border-color: transparent;
  }
  50% {
      border-color: #e3e3e3;
  }
}

@keyframes titleLoad {
  0%{
      opacity: 0%;
      width: 0ch;
  }
  10%{
      width: 4ch;
      
  }
  20%{
      width: 6ch;
  }
  30%{
      width: 9.3ch;
      opacity: 100%;
  }
  95%{
      opacity: 0%;
  }
  100%{
      opacity: 0%;
      /* display: none !important; */
      /* position: absolute;
      left: -10000px; */
  }
}

@keyframes removeDiv {
  0% {
      height: 20vh;
      display: flex;
      align-items: center;
      justify-content: space-around;
  }
  90%{
      height: 20vh;
      display: flex;
      align-items: center;
      justify-content: space-around;
  }
  100% {
      position: absolute;
      left: -10000px;
      display: none;
  }
} 




  


/* Type writer effect */

/* @keyframes caret {
  0%, 100% {
      border-color: transparent;
  }
  50% {
      border-color: #e3e3e3;
  }
}

@keyframes typing {
  0%{
      width: 0ch;
  }
  30%{
      width: 9.3ch;
  }
  80% {
      width: 9.3ch;
  }
  90% {
      width: 0ch;
  }
  100% {
      width: 0ch;
  }
} */